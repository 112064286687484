<template>
	<div class="contain flex">
		<div class="page-content">
			<div class="tabContent">
				<div style="width:320px;margin:0 auto;">
					<el-input placeholder="请输入您需要搜索的关键字" size="mini"> <i slot="suffix"
							class="el-input__icon el-icon-search"></i></el-input>
				</div>
				<div class="zk-list-item" @click="gozkInfoPage(item.id)" v-for="item in newslist" :key="item.id">
					<div class="zk-header">
						<div class="zk-left">{{ item.name }}</div>
						<div class="zk-right">{{ utils.stringToDate(item.createTime) }}</div>
					</div>
					<div class="zk-foot">
						<div class="zk-left">
							报名时间：{{ utils.stringToDate(item.enrollStartTime) }}—{{ utils.stringToDate(item.enrollEndTime) }}
						</div>
						<div class="zk-right">
							审核时间：{{ utils.stringToDate(item.auditStartTime) }}—{{ utils.stringToDate(item.auditEndTime) }}
						</div>
					</div>
				</div>
				<el-empty v-if="newslist.length < 1" :image-size="200"></el-empty>
				<div style="text-align: center; margin-top: 20px">
					<el-pagination hide-on-single-page @size-change="handleSizeChange"
						@current-change="handleCurrentChange" :current-page="selfrom.pageNumber"
						:page-sizes="[20, 50, 100, 150, 200]" :page-size="selfrom.pageSize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import utils from "@/assets/js/utils";
	import {
		isnull
	} from "@/assets/js/index";
	import {
		GetExamList
	} from "@/api/home";
	export default {
		data() {
			return {
				selfrom: {
					pageNumber: 1,
					pageSize: 20,
					KM: "",
				},
				total: 0,
				newslist: [],
			}
		},
		created() {
			this.getPage()
		},
		methods: {
			gozkInfoPage(id) {
				this.$router.push("zhaokaoInfo?id=" + id)
			},
			getPage() {
				var _this = this;
				GetExamList(this.selfrom).then((res) => {
					if (res.success) {
						_this.newslist = res.data.rows;
						_this.total = res.data.total;
					}
				});
			},
			handleSizeChange(val) {
				this.selfrom.pageNumber = 1;
				this.selfrom.pageSize = val;
				this.getPage();
			},
			handleCurrentChange(val) {
				this.selfrom.pageNumber = val;
				this.getPage();
			},
			search() {
				this.selfrom.pageNumber = 1;
				this.getPage();
			},
		}
	}
</script>
<style lang="scss" scoped>
	.page-content {
		width: 1200px;
		margin: 0 auto;
		padding: 20px 0;

		::v-deep .el-tabs__header {
			margin: 0;
		}

		::v-deep .el-tabs__item {
			padding: 0 80px;
		}
	}
	.tabContent {
		background: #FFFFFF;
		padding: 20px;

	}

	.zk-list-item {
		margin-top: 20px;
		background: #f8f8f8;
		padding: 2px 0;
		cursor: pointer;
	}

	.zk-header {
		display: flex;
		padding: 8px 16px;
		border-bottom: 1px solid #cccccc;
		padding-left: 0;

		.zk-left {
			flex: 1;
			font-size: 14px;
			color: #333333;
			background: url(../../assets/img/left_1x.png) no-repeat 10px center;
			padding-left: 32px;
		}

		.zk-right {
			width: 150px;
			font-size: 13px;
			color: #898989;
			text-align: right;
		}
	}

	.zk-foot {
		display: flex;
		padding: 8px 16px;
		padding-left: 0;

		.zk-left {
			flex: 1;
			color: #1990FF;
			font-size: 12px;
			padding-left: 32px;
		}

		.zk-right {
			width: 320px;
			font-size: 12px;
			color: #1990FF;
			text-align: right;
		}
	}

	.zk-pagination {
		text-align: right;
		padding: 30px 0;
		padding-bottom: 0px;
	}
</style>
